<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-04-06 12:54:46
 * @LastEditTime : 2021-04-07 09:35:18
 * @LastEditors  : XiaoLei
-->
<template>
  <div>
    <img v-for="(i, index) in item" :key="index" :src="i" alt="">
  </div>
</template>

<script>
  import { getProtocol } from '@/api/api';

  export default {
    name: 'Protocol',
    data() {
      return {
        item: {}
      }
    },
    created () {
      const { id } = this.$route.params;
      getProtocol({id}).then(res => {
        this.item = res.data?.protocolImg?.split(',');
      });
    },
  }
</script>

<style scoped>
  img{
    width: 100%;
  }
</style>